import React from 'react'
import about from '../../images/about/story.jpg'


const About = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="pt-[40px] pb-[100px] border border-[#F2EFE8] border-t-[33px]">
            <div className="wraper">
                <div className="grid grid-cols-12 items-center gap-3">
                    <div className="col-span-12 pb-[40px]">
                        <h2 className="text-[#202020] text-[24px] text-center font-bold relative pb-[8px] before:absolute before:content-[''] before:bottom-0 before:transform before:-translate-x-[10px] before:w-[120px] before:h-[3px] before:bg-[#999999] before:rounded-full before:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">Lectures</h2>
                    </div>
                    <div className="col-span-7 md:col-span-12">
                        <div className="mb-[20px]">
                            <div className="pl-[10px] mt-[23px]">
                                <h3 className="text-[#000] text-[24px] leading-[28px] mb-[10px] font-normal">Oct. 21, 2025 – Las Vegas</h3>
                                <h3 className="text-[#000] text-[24px] leading-[28px] mb-[20px] font-normal">METALCON</h3>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">On Tuesday, Oct. 21 from 8.30 a.m. – 12.30 p.m., Rob, will present Metal Roofing From A (Aluminum) to Z (Zinc). Often billed as "Metal Roofing 101" and known as the best “crash course” available for all in-industry personnel, it includes technical information presented in a common-sense fashion that makes the art and science of metal roofing clear, concise and understandable. Contractors, salespeople, architects, specifiers, roof consultants, engineers, building owners and maintenance staff will all benefit from attending.</p>
                                <a onClick={ClickHandler} target="_blank" href="https://metalcon.com/" className="bg-[#867662] relative cursor-pointer text-[22px] font-semibold text-white px-[8px] py-[8px] mt-[0px] mb-[100px] capitalize inline-block transition ease-in-out duration-300 hover:bg-[#d4c291] border border-[#BEB499] border-[2px]" rel="noreferrer">Register Here <i className='ti-angle-right'></i></a>



                                <h3 className="text-[#000] text-[24px] leading-[28px] mb-[10px] font-normal">Dec. 4-5, 2025 – Madison, WI</h3>
                                <h3 className="text-[#000] text-[24px] leading-[28px] mb-[20px] font-normal">The University of Wisconsin–Madison College of Engineering</h3>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">Rob presents Metal Roofing Systems at the University of Wisconsin Madison’s School of Engineering on Dec. 4-5, 2025 at the Fluono Center, located on the UW-Madison campus, with an online option also available. If you design, install, commission, maintain or repair metal roofing systems, this course will provide you with the tools and techniques to do your job correctly and avoid problems. Upon completion, you will be able to identify the best solutions to your metal roofing issues, whether you’re working on new construction, performing maintenance or re-roofing. This 2-day course includes technical and practical “soup-to-nuts” information presented in a common-sense fashion that makes the art and science of metal roofing clear, concise and understandable. For a full course description, visit the University of Wisconsin’s engineering program summary</p>
                                <a onClick={ClickHandler} target="_blank" href="https://interpro.wisc.edu/courses/metal-roofing-systems/" className="bg-[#867662] relative cursor-pointer text-[16px] font-semibold text-white px-[8px] py-[8px] mt-[0px] mb-[20px] capitalize inline-block transition ease-in-out duration-300 hover:bg-[#d4c291] border border-[#BEB499] border-[2px]" rel="noreferrer">Learn More Here <i className='ti-angle-right'></i></a>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[5px] font-bold">Who Should Attend</p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">Contractors, architects, specifiers, roof consultants, engineers, building owners, manufacturers, building envelope commissioning authorities and maintenance staff will benefit from this course—basically anyone involved in metal roofing design, construction, maintenance, repair and re-roofing. And, it is the best “crash course” available for sales personnel in-industry.</p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">You can receive an early bird discount of $150 by entering code S-5DIS in the promo code box during step three of the registration process.</p>
                                <a onClick={ClickHandler} target="_blank" href="https://uw.ungerboeck.com/prod/emc00/PublicSignIn.aspx?&aat=7a685171464a6f6c72644e354e433657554842306a373448704f49596641547733476d626673664f6a656b3d" className="bg-[#867662] relative cursor-pointer text-[22px] font-semibold text-white px-[8px] py-[8px] mt-[0px] mb-[100px] capitalize inline-block transition ease-in-out duration-300 hover:bg-[#d4c291] border border-[#BEB499] border-[2px]" rel="noreferrer">Register Here <i className='ti-angle-right'></i></a>




                                <h3 className="text-[#000] text-[24px] leading-[28px] mb-[10px] font-normal">January 20-22, 2026 – Las Vegas</h3>
                                <h3 className="text-[#000] text-[24px] leading-[28px] mb-[20px] font-normal">International Roofing Expo </h3>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">Rob will present an introduction to Understanding Metal Roofing: Part I. Often billed as "Metal Roofing 101," and known as the best “crash course” available for all in-industry personnel, it includes technical information presented in a common-sense fashion that makes the art and science of metal roofing clear, concise and understandable. Contractors, salespeople, architects, specifiers, roof consultants, engineers, building owners and maintenance staff will all benefit from attending.</p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">Then, later that same morning Haddock will present Understanding Metal Roofing: Part II, supplementing and expanding the metal roofing essentials covered in Part I earlier that day. </p>
                                <a onClick={ClickHandler} target="_blank" href="https://www.theroofingexpo.com/en/home.html" className="bg-[#867662] relative cursor-pointer text-[22px] font-semibold text-white px-[8px] py-[8px] mt-[0px] mb-[100px] capitalize inline-block transition ease-in-out duration-300 hover:bg-[#d4c291] border border-[#BEB499] border-[2px]" rel="noreferrer">Register Here <i className='ti-angle-right'></i></a>

                            </div>
                        </div>
                    </div>
                    <div className="col-span-5 md:col-span-12">
                        <div className="relative mb-[20px] ml-[70px]">
                            <div className=" relative max-w-[520px]">
                                <img className="w-full" src={about} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default About;